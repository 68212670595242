const originalFetch = window.fetch

window.fetch = async (...args) => {
  const response = await originalFetch(...args)
  if (response.status === 401) {
    sessionStorage.setItem('isLoggedIn', false)
    history.replaceState(null, '', '/v2/login')
    location.href = '/v2/login'
  }
  return response
}
