import useAppointmentsSubscription from '@/v2/hooks/subscriptions/useAppointmentsSubscription'
import useTimeOffsSubscription from '@/v2/hooks/subscriptions/useTimeOffsSubscription'
import usePatientsSubscription from '@/v2/hooks/subscriptions/usePatientsSubscription'

const SubscriptionContainer = ({ children }) => {
  useAppointmentsSubscription()
  useTimeOffsSubscription()
  usePatientsSubscription()

  return children
}

export default SubscriptionContainer
