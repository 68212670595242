import React, { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter as Router } from 'react-router-dom'
import apolloClient from '@/v2/graphql/configs/ApolloClient'
import ThemeContainer from '@/v2/components/layouts/ThemeContainer'
import { TabProvider } from '@/v2/contexts/TabsContext'
import { SnackbarProvider } from '@/v2/contexts/SnackbarContext'
import { AuthProvider } from '@/v2/contexts/AuthContext'
import SubscriptionContainer from '@/v2/SubscriptionContainer'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import '@/v2/utils/globalFetch'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 20, // 20 seconds
    },
  },
})

const AppV2 = () => {
  useEffect(() => {
    const startSentry = async () => {
      if (!((navigator.brave && (await navigator.brave.isBrave())) || false)) {
        Sentry.init({
          dsn: process.env.SENTRY_REACT_DSN,
          integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
          environment:
            window.location.host === 'smilebar.clearworks-staging.xyz'
              ? 'staging'
              : process.env.NODE_ENV,
          tracesSampleRate: 0.1,
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1.0,
          ignoreErrors: ['NotAllowedError'],
        })
      }
    }
    startSentry()
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={apolloClient}>
        <SnackbarProvider>
          <TabProvider>
            <Router>
              <AuthProvider>
                <SubscriptionContainer>
                  <ThemeContainer />
                </SubscriptionContainer>
              </AuthProvider>
            </Router>
          </TabProvider>
        </SnackbarProvider>
      </ApolloProvider>
    </QueryClientProvider>
  )
}

export default AppV2
