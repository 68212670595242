import { useSubscription } from '@apollo/client'

import {
  updateAppointmentsCounter,
  appendAppointment,
  removeAppointment,
} from '@/v2/cache/updates'

import AppointmentScheduled from '@/v2/graphql/subscriptions/AppointmentScheduled.gql'
import AppointmentRemoved from '@/v2/graphql/subscriptions/AppointmentRemoved.gql'

const useAppointmentsSubscription = () => {
  useSubscription(AppointmentRemoved, {
    onSubscriptionData: ({ client, subscriptionData }) => {
      const { appointmentRemoved } = subscriptionData.data

      if (appointmentRemoved) {
        removeAppointment(client, appointmentRemoved)
        updateAppointmentsCounter(client, appointmentRemoved, -1)
      }
    },
  })

  useSubscription(AppointmentScheduled, {
    onSubscriptionData: ({ client, subscriptionData }) => {
      const { appointmentScheduled } = subscriptionData.data

      if (appointmentScheduled) {
        updateAppointmentsCounter(client, appointmentScheduled, 1)
        appendAppointment(client, appointmentScheduled)
      }
    },
  })
}

export default useAppointmentsSubscription
