import React from 'react'
import ReactDOM from 'react-dom'
import MainBoundary from '@/v2/components/boundaries/MainBoundary'
import AppV2 from '@/v2/'

document.addEventListener('DOMContentLoaded', () => {
  const clearworksApp = document.createElement('div')
  clearworksApp.setAttribute('id', 'clearworks-app')

  const root = document.body.appendChild(clearworksApp)

  if (!window.location.href.includes('/v2')) {
    const url = window.location.href.replace(
      window.location.origin,
      `${window.location.origin}/v2`
    )
    window.location.href = url
    return null
  }

  if (root) {
    ReactDOM.render(
      <MainBoundary showSVG={false}>
        <AppV2 />
      </MainBoundary>,
      root
    )
  }
})
