import get from 'lodash/get'

import Datetime from '@/v2/modules/Datetime'

import DayScheduleFragment from '@/v2/graphql/queries/fragments/DayScheduleInfo.gql'

const appendTimeOff = (cache, timeOff) => {
  if (timeOff.id) {
    const startsAt = Datetime.fromISO(get(timeOff, 'startsAt'))
    const fragmentId = `DaySchedule:${startsAt.toQueryFormat()}`

    try {
      const result = cache.readFragment({
        id: fragmentId,
        fragment: DayScheduleFragment,
        fragmentName: 'DayScheduleInfo',
      })

      const alreadyInList = result.timeOffs.find(({ id }) => id === timeOff.id)

      if (!alreadyInList) {
        cache.writeFragment({
          id: fragmentId,
          fragment: DayScheduleFragment,
          fragmentName: 'DayScheduleInfo',
          data: {
            ...result,
            timeOffs: [...result.timeOffs, timeOff],
          },
        })
      }
    } catch (e) {
      // Trying to write an undefined cache. Ignoring exception.
    }
  }
}

export default appendTimeOff
