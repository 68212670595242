import { useSubscription } from '@apollo/client'

import { appendTimeOff, removeTimeOff } from '@/v2/cache/updates'

import TimeOffRemoved from '@/v2/graphql/subscriptions/TimeOffRemoved.gql'
import TimeOffCreated from '@/v2/graphql/subscriptions/TimeOffCreated.gql'

const useTimeOffsSubscription = () => {
  useSubscription(TimeOffRemoved, {
    onSubscriptionData: ({ client, subscriptionData }) => {
      const { timeOffRemoved } = subscriptionData.data

      if (timeOffRemoved) {
        removeTimeOff(client, timeOffRemoved)
      }
    },
  })

  useSubscription(TimeOffCreated, {
    onSubscriptionData: ({ client, subscriptionData }) => {
      const { timeOffCreated } = subscriptionData.data

      if (timeOffCreated) {
        appendTimeOff(client, timeOffCreated)
      }
    },
  })
}

export default useTimeOffsSubscription
